//Block right click
document.addEventListener(
  "contextmenu",
  (e) => {
    e.preventDefault();
  },
  false
);

//Disable View Source
document.addEventListener("keydown", (e) => {
  // USE THIS TO DISABLE CONTROL AND ALL FUNCTION KEYS
  // if (e.ctrlKey || (e.keyCode>=112 && e.keyCode<=123)) {
  // THIS WILL ONLY DISABLE CONTROL AND F12
  if (e.ctrlKey || e.keyCode == 123) {
    e.stopPropagation();
    e.preventDefault();
  }
});

// Countdown

var today = new Date(2024, 9, 4);
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1;
let dd = today.getDate();
if (dd < 10) dd = "0" + dd;
if (mm < 10) mm = "0" + mm;
const formattedToday = yyyy + "-" + mm + "-" + dd + " 22:00:00";

const closeInfoDate = dd + "." + mm + "." + yyyy;
const closeInfoDayInt = today.getDay();
var closeInfoDay = today.getDay();

switch (closeInfoDayInt) {
  case 0:
    closeInfoDay = "niedzielę";
    break;
  case 1:
    closeInfoDay = "poniedziałek";
    break;
  case 2:
    closeInfoDay = "wtorek";
    break;
  case 3:
    closeInfoDay = "środę";
    break;
  case 4:
    closeInfoDay = "czwartek";
    break;
  case 5:
    closeInfoDay = "piątek";
    break;
  case 6:
    closeInfoDay = "sobotę";
    break;
  default:
    break;
}

var closeInfo =
  "Zapisy zamykamy w " + closeInfoDay + " (" + closeInfoDate + ") o 22:00";

var closeInfoSpans = document.getElementsByClassName("start-course__label");
for (var closeInfoSpan of closeInfoSpans) closeInfoSpan.innerHTML = closeInfo;

var countDownDate = new Date(formattedToday).getTime();

var intervalcountDownDate = setInterval(function () {
  var now = new Date().getTime();
  var distance = countDownDate - now;

  var days = 0;
  var hours = 0;
  var minutes = 0;
  var seconds = 0;

  if (distance > 0) {
    days = Math.floor(distance / (1000 * 60 * 60 * 24));
    hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((distance % (1000 * 60)) / 1000);
  } else {
    clearInterval(intervalcountDownDate);
  }

  var daysSpan = document.getElementsByClassName("countdown-timer__days");
  var hoursSpan = document.getElementsByClassName("countdown-timer__hours");
  var minutesSpan = document.getElementsByClassName("countdown-timer__minutes");
  var secondsSpan = document.getElementsByClassName("countdown-timer__seconds");

  for (var daySpan of daysSpan)
    daySpan.innerHTML =
      days + "<small class='countdown-timer__label'>Dni</small>";

  for (var hourSpan of hoursSpan)
    hourSpan.innerHTML =
      hours + "<small class='countdown-timer__label'>Godzin</small>";

  for (var minuteSpan of minutesSpan)
    minuteSpan.innerHTML =
      minutes + "<small class='countdown-timer__label'>Minut</small>";

  for (var secondSpan of secondsSpan)
    secondSpan.innerHTML =
      seconds + "<small class='countdown-timer__label'>Sekund</small>";
}, 1000);

// Counters
function isInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    Math.round(rect.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    Math.round(rect.right) <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

const box = document.querySelector(".counter-block");

var checkCounterIsInViewPort = function () {
  if (!isInViewport(box)) return;

  for (var counter of counters) {
    counter.innerText = "0";
    updateCounter(counter);
  }

  document.removeEventListener("scroll", checkCounterIsInViewPort);
};

document.addEventListener("scroll", checkCounterIsInViewPort, {
  passive: true,
});

var counters = document.getElementsByClassName("counter");

function updateCounter(counterParam) {
  var targetValue = +counterParam.getAttribute("data-target");
  var actualValue = +counterParam.innerText;

  var increment = targetValue / 300;

  if (actualValue < targetValue) {
    counterParam.innerText = `${Math.ceil(actualValue + increment)}`;
    setTimeout(updateCounter, 1, counterParam);
  } else {
    counterParam.innerText = targetValue;
  }
}

//WriteText

var textSpan = document.getElementById("write-text");
//var text = 'Naucz się frontendu i zacznij tworzyć kompletne, zaawansowane i piękne strony internetowe od A do Z!';
var text =
  "Tworzenie stron internetowych nie musi być trudne – naucz się HTML i CSS z naszym szkoleniem!";
var index = 1;

writeText();

function writeText() {
  textSpan.innerText = text.slice(0, index);

  index++;

  if (index > text.length) {
    setTimeout(function () {
      index = 1;
      setTimeout(writeText, 60);
    }, 2000);
    return;
  }

  setTimeout(writeText, 60);
}

// Carousel
// opinion__dot--fill
var opinions = document.getElementsByClassName("carousel__all")[0];
var leftArrow = document.getElementsByClassName("carousel__arrow-left")[0];
var rightArrow = document.getElementsByClassName("carousel__arrow-right")[0];

var opinion = document.getElementsByClassName("carousel__content");

var dots = document.getElementsByClassName("carousel__dot");

var indexCarousel = 0;

var interval = setInterval(startCarousel, 4000);

function startCarousel() {
  indexCarousel++;
  changeOpinion();
}

function changeOpinion() {
  if (indexCarousel > opinion.length - 1) {
    indexCarousel = 0;
  } else if (indexCarousel < 0) {
    indexCarousel = opinion.length - 1;
  }

  opinions.style.transform = `translateX(${-indexCarousel * 60}vw)`;

  var filledDots = document.getElementsByClassName("carousel__dot--fill");

  for (var elements of filledDots) {
    elements.classList.remove("carousel__dot--fill");
  }

  dots[indexCarousel].classList.add("carousel__dot--fill");
}

function resetInterval() {
  clearInterval(interval);
  interval = setInterval(startCarousel, 4000);
}

rightArrow.addEventListener("click", () => {
  indexCarousel++;
  changeOpinion();
  resetInterval();
});

leftArrow.addEventListener("click", () => {
  indexCarousel--;
  changeOpinion();
  resetInterval();
});

// Collapisble

var collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("collapsible--expanded");
  })
);

// Modal
var modal = document.getElementById("myModal");

var btns = document.getElementsByClassName("modal-open");

var modalSuccess = document.getElementsByClassName("modal__success")[0];

modalSuccess.style.display = "none";

for (var btn of btns) {
  btn.onclick = function () {
    modal.style.display = "block";
  };
}

var spanClose = document.getElementsByClassName("close")[0];

spanClose.onclick = function () {
  modal.style.display = "none";
  modalSuccess.style.display = "none";
};

window.onclick = function (event) {
  if (event.target == modal) {
    modal.style.display = "none";
    modalSuccess.style.display = "none";
  }
};

function submitForm() {
  var form = document.getElementById("myForm");

  if (!form.checkValidity()) {
    if (form.reportValidity) {
      form.reportValidity();
    }
  } else {
    callAjax();
  }
}

function callAjax() {
  document.getElementById("loader").style.display = "flex";
  var emailnputValue = document.getElementById("email").value.trim();

  $.ajax({
    url: "https://www.modestprogrammer.pl/api/myapi",
    type: "POST",
    data: {
      email: emailnputValue,
      api_key: "9812",
    },
    success: function (returnhtml) {
      document.getElementById("email").value = "";
      modalSuccess.style.display = "block";
      document.getElementById("loader").style.display = "none";
    },
  });
}
